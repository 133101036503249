import { useTranslations } from 'next-intl';
import { Beardman404 } from '~/components/Beardman/Beardman404/Beardman404';
import { BeardmanError } from '~/components/Beardman/BeardmanError/BeardmanError';

export const ErrorPage = ({ statusCode }: { statusCode?: number }) => {
  const t = useTranslations('error');

  return (
    <div className="error-page">
      <div className="container-fluid-centered">
        <div className="error">
          {statusCode !== 404 ? <BeardmanError /> : <Beardman404 />}
          {!!statusCode && <h2 className="vp-title-xl">{statusCode}</h2>}
          <p className="vp-body-l">
            {t(
              statusCode !== 404
                ? 'global.description'
                : '404.description',
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
